import './App.css';
import P5Sketch from './component/sketchDraw.js';
import {React,useState,useEffect  } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { createGenericFile,publicKey} from '@metaplex-foundation/umi';
import axios from 'axios';
import { mplCore,
  Key, getAssetV1GpaBuilder,
  updateAuthority, } from '@metaplex-foundation/mpl-core';
import { walletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters';
import { notification,Button } from 'antd';
import { irysUploader } from '@metaplex-foundation/umi-uploader-irys'
import './Modal.css';
import { PinataSDK } from "pinata-web3";



require('@solana/wallet-adapter-react-ui/styles.css');
 
const bs58 = require('bs58');


function App() {

  const { connection } = useConnection();
  const wallet = useWallet();
  const [inputValue, setInputValue] = useState('');
  const [imgURL, setimgURL] = useState('');
  const [collsize, setcollsize] = useState(0);
  const [genBtnClicked, setgenBtnClicked] = useState(false);
  const [genBtndisabled, setgenBtndisabled] = useState(true);
  const [mintBtndisabled, setmintBtndisabled] = useState(true);
  const [isloadingFormint, setisloadingFormint] = useState(false);
  const [isImgloading, setisImgloading] = useState(false);


    
    

  const umi =  
          createUmi(connection)
         .use(walletAdapterIdentity(wallet))
         .use(irysUploader())
         .use(mplCore())


  const axiosoptions = {
    headers: {
      'X-API-KEY': process.env.REACT_APP_API_KEY,
      'accept': 'application/json'
    }
  };

  const pinata = new PinataSDK({
    pinataJwt:  process.env.REACT_APP_JWT,
    pinataGateway: "gray-broad-jaguar-102.mypinata.cloud",
  });
  

  const Config ={
    name:"TWBC",
    symbol:"TWBC",
    desc:"*****************",
    _collection:"HQUc6JsUp6Ap4uG6FvkTgBeeZjnzsm7bizpa5Dh8c494"
  }

// Antd Notification 

const [api, contextHolder] = notification.useNotification();

const openNotification = (placement) => {
  api.info({
    message: 'Connect your wallet!',
    placement,
  });
  setmintBtndisabled(false);  //if there is any error enable mint button again
};

const SomtingWrongNotification = (type) => {
  api[type]({
    message: 'Somting wrong!',
  });
  setmintBtndisabled(false);  //if there is any error enable mint button again
}
const SomeonebeforeyouNotif  = (type) => {
  api[type]({
    message: 'Someone came before you!',
  });
  setmintBtndisabled(false);  //if there is any error enable mint button again
}
const failedtosendTxNotif = (type) => {
  api[type]({
    message: 'failed to send transaction',
  });
  setmintBtndisabled(false);  //if there is any error enable mint button again
}
const rejectedrequestNotif = (type) => {
  api[type]({
    message: 'User rejected the request',
  });
  setmintBtndisabled(false);  //if there is any error enable mint button again
}
const artgottenNotif  = (type) => {
  api[type]({
    message: 'This art is gotten!',
  });
  setmintBtndisabled(false);  //if there is any error enable mint button again
}
const NftexpendableNotif  = (type) => {
  api[type]({
    message: 'Nft expendable!',
  });
  setmintBtndisabled(false);  //if there is any error enable mint button again
}
const Mintsuccess  = (type) => {
  api[type]({
    message: 'mint successful',
  });
  setmintBtndisabled(false);  //if there is any error enable mint button again
}


//

const fetchPfp=async()=>{
  try {
   if (inputValue !== '') {
    const res =await axios.get(`${process.env.REACT_APP_API_URL}/${inputValue}`,axiosoptions);
    if (res.data.image_url) {
      if (await IsPfpGotten(inputValue)) {
        setimgURL(res.data.image_url);
        NftexpendableNotif('error');    
      }else{
        setimgURL(res.data.image_url);
        setgenBtndisabled(false);
      }
    }
  }} catch (error) {
    console.log(error);
    SomtingWrongNotification('error');
  }

}

// const getImageBuffer = async (imgurl) => {
//   try {

//     const response = await axios.get(imgurl,  { responseType: 'arraybuffer' })
//     const buffer = Buffer.from(response.data, "utf-8")

//     return buffer

//   } catch (error) {
//     console.error('Error fetching image:', error);
//     throw error; 
//   }
// };

// const uploadImg=async(imgurl)=>{
//   try {
//     console.log(imgurl);
//    const  bufr = await getImageBuffer(imgurl);
//    const genImag =  createGenericFile(bufr,'Image');
//   const [imageUri] = await umi.uploader.upload([genImag]);

//   console.log(1);
  
//   return imageUri;

//   } catch (error) {
//     console.log(error);
//     SomtingWrongNotification('error');
//     throw error; 
//   }
// }

// const uploadJson=async(imgUri)=>{
//   try { 

//       const uri = await umi.uploader.uploadJson({
//       name: Config.name,
//       symbol: Config.symbol,
//       description: Config.desc,
//       image: imgUri,
//       })

//       console.log(2);
//       return uri

//     } catch (error) {
//     console.log(error);
//     SomtingWrongNotification('error');
//     throw error; 
//   }

// }
 
const UploadMetadata=async(Imgdataurl)=>{
  try {

    console.log("START!!!");
   
    const base64String = Imgdataurl.split(",")[1];

    const upload = await pinata.upload.base64(base64String);

    const uploadJson = await pinata.upload.json({
        name: Config.name,
        symbol: Config.symbol,
        description: Config.desc,
        image: `https://gray-broad-jaguar-102.mypinata.cloud/ipfs/${upload.IpfsHash}`
      })

      const Uri =`https://gray-broad-jaguar-102.mypinata.cloud/ipfs/${uploadJson.IpfsHash}`;

      console.log(Uri);
     

    return Uri;

  } catch (error) {
    console.log(error);
    throw error; 
  }
  
}


async function SizeOfColl (){

  const collection = publicKey(Config._collection)

  const assetsByCollection = await getAssetV1GpaBuilder(umi)
    .whereField('key', Key.AssetV1)
    .whereField('updateAuthority', updateAuthority('Collection', [collection]))
    .getDeserialized()

     setcollsize(assetsByCollection.length); //take currente size of collection
  
}

const handlePaste = (e) => {

  const pasteData = e.clipboardData.getData('text');

   setInputValue(pasteData);
  e.preventDefault(); // Prevent the default paste behavior
  
};

async function IsPfpGotten (mint) {
  try {

  const collection = publicKey(Config._collection)

  const assetsByCollection = await getAssetV1GpaBuilder(umi)
    .whereField('key', Key.AssetV1)
    .whereField('updateAuthority', updateAuthority('Collection', [collection]))
    .getDeserialized()
  
    for (let i = 0; i < assetsByCollection.length; i++) {
      if (assetsByCollection[i].attributes) {

       if (assetsByCollection[i].attributes.attributeList[0].value === mint || assetsByCollection[i].publicKey === mint) {
           return true;
       }
     }
    }
  return false; 
} catch (error) {
    SomtingWrongNotification('error');
    console.error(error);
    throw error; 
}
}


const getImageFromDiv = () => {
  try {
  
    const divElement = document.getElementById('sketchImg');
    if (divElement) {

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const newWidth = 600; // New width in pixels
    const newHeight = 600; // New height in pixels
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Draw the image onto the canvas with the new dimensions
    ctx.drawImage(divElement, 0, 0, newWidth, newHeight);

    const imageDataURL = canvas.toDataURL('image/png');

return imageDataURL;
}
} catch (error) {
    console.error(error);
    throw error; 
}
};


const Mint=async()=>{
  try {

    setmintBtndisabled(true); // disabled mint button 

    if (wallet.publicKey) {
   
    if (await IsPfpGotten(inputValue)) {
      artgottenNotif ('error');
    }else{
     setisloadingFormint(true); 
     let imgdataurl = getImageFromDiv();
     const Uri = await UploadMetadata(imgdataurl);

     if (Uri) {
    
   const Config_Backend ={
     owner:wallet.publicKey,
     uri:Uri,
     genesisMint:inputValue
   }

  await axios.post(process.env.REACT_APP_BACKEND_SIGNER,Config_Backend)
  .then(async(res)=>{

    if (!res.data) {
      SomeonebeforeyouNotif ('error');
    }else{

   const values = Object.values(res.data);
   const serialized =Uint8Array.from(values);
   const deserialized = umi.transactions.deserialize(serialized);
   const SignTx = await umi.identity.signTransaction(deserialized)
   .catch(error => {
    rejectedrequestNotif('error');
    setisloadingFormint(false);
    throw new Error("User rejected the request");
   });

   //check if someone get Pfp generat before send a transaction
   if(await IsPfpGotten(inputValue)){
    setisloadingFormint(false);
    SomeonebeforeyouNotif ('error');
   }else{

   const signature = await umi.rpc.sendTransaction(SignTx)
   .catch(error => {
    console.error(error)
    setisloadingFormint(false);
    failedtosendTxNotif('error'); 
    throw new Error("failed to send transaction");
  });

   await umi.rpc.confirmTransaction(signature, {
    strategy: { type: 'blockhash', ...(await umi.rpc.getLatestBlockhash()) }
  });

  if (signature) {
    setisloadingFormint(false);
    Mintsuccess('success');
    console.log(bs58.encode(signature));
  } 

}}
})
.catch(error=>{
  console.error(error);
  setisloadingFormint(false);
  SomtingWrongNotification('error');
})
    
}}
  }else{
    openNotification('top'); 
  }
} catch (error) {
    console.error(error);
    setisloadingFormint(false);
}
}



  useEffect(() => {
      setgenBtnClicked(false); 
      setgenBtndisabled(true);
      SizeOfColl();
     if (!inputValue) {
      setimgURL('');
      setisImgloading(false)
     }else{
      fetchPfp();
      setisImgloading(true)
     }
  }, [inputValue]);
 

  return (
    <div>
      <div className="desktop-div">
        
    <div className='page'>
     {contextHolder}

    <div className='Head'>
      <div className='TitleHead'>
        <h3>This will be cool</h3>
      </div>
      <div className='WalletBtn'>
        <WalletMultiButton />
      </div>
    </div>

    <div className='body'>

    <div className='NumMinted'>
        <h3>Minted : {collsize}</h3>
    </div>
      
    <div className='SerchMint'>
    <input
        type="text"
        onChange={(e)=>{e.preventDefault();}}
        onPaste={handlePaste}
        value={inputValue}
        placeholder="Paste mint address Here "
        size={30}
      />
      <div className='ClearBtn'>
        <Button size='small' onClick={()=>setInputValue('')}>Clear</Button>
      </div>
    </div>
   
    <div className='imgClass'>

      <div className='SrcImg'>
        {isImgloading?
        imgURL? <img src={imgURL} width={300} height={300} alt='PFP'/>
        :<p>loading...</p>
        :null
        }
      </div>
      <div>
       <Button onClick={()=>{setgenBtnClicked(true);setmintBtndisabled(false)}} disabled={genBtndisabled}> generat</Button>
      </div>
      
      <div className='GenPfp'>
         {genBtnClicked ?
          <P5Sketch imageUrl={imgURL}/>
           :null} 
      </div> 

    </div>

    <div className='MintBtn'>
        <Button onClick={Mint} disabled={mintBtndisabled}>Mint</Button>
      </div>

    </div>
     <Modal isOpen={isloadingFormint} />
    </div>
    </div>

    {/* this is mobile version  */}

     <div className="mobile-div">
      
     <div className='page'>
     {contextHolder}

     <div className='Head'>
      <div className='TitleHead'>
        <h3>This will be cool</h3>
      </div>
      <div className='WalletBtn'>
        <WalletMultiButton />
      </div>
    </div>

    <div className='body'>

    <div className='NumMinted'>
        <h3>Minted : {collsize}</h3>
    </div>
      
    <div className='SerchMint'>
    <input
        type="text"
        onChange={(e)=>{e.preventDefault();}}
        onPaste={handlePaste}
        value={inputValue}
        placeholder="Paste mint address Here "
        size={30}
      />
      <div className='ClearBtn'>
        <Button size='small' onClick={()=>setInputValue('')}>Clear</Button>
      </div>
    </div>
   
    <div className='imgClass'>

      <div className='SrcImg'>
      {isImgloading?
        imgURL? <img src={imgURL} width={300} height={300} alt='PFP'/>
        :<p>loading...</p>
        :null
        }
      </div>
      
      <div className='GenBtn'>
       <Button onClick={()=>{setgenBtnClicked(true);setmintBtndisabled(false)}} disabled={genBtndisabled}> generat</Button>
      </div>
      
      <div className='GenPfp'>
         {genBtnClicked ?
          <P5Sketch imageUrl={imgURL}/>
           :null} 
      </div> 

    </div>

    <div className='MintBtn'>
        <Button onClick={Mint} disabled={mintBtndisabled}>Mint</Button>
      </div>

    </div>
    <Modal isOpen={isloadingFormint} />
    </div>
    </div>

     </div>

  
  );
}

export default App;


const Modal = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" >
      <div className="modal-content" >
      <div className="loader">
          <div className="dot" />
        </div>
        <div className="loader">
          <div className="dot" />
        </div>
        <div className="loader">
          <div className="dot" />
        </div>
        <div className="loader">
          <div className="dot" />
        </div>
        <div className="loader">
          <div className="dot" />
        </div>
        <div className="loader">
          <div className="dot" />
        </div>
    </div>
    </div>
  );
};

