import { useMemo } from "react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter
  } from "@solana/wallet-adapter-wallets";
  import { 
    ConnectionProvider,
    WalletProvider
  } from "@solana/wallet-adapter-react";
import App from "./App";




export default function WalletAdapter() {

    const wallets = useMemo(
        () => [
          new PhantomWalletAdapter(),
          new SolflareWalletAdapter()
        ],
        []
      );
 

  return (
    <ConnectionProvider  endpoint={process.env.REACT_APP_CLUSTER_URL}>
    <WalletProvider  wallets={wallets} autoConnect >
    <WalletModalProvider>
         <App />
    </WalletModalProvider>
    </WalletProvider>
    </ConnectionProvider>
  );
}

