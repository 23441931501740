import React from 'react';

class P5Sketch extends React.Component {
    state = {
        canvasDataURL: null,
        isLoading: true,
    };

    componentDidMount() {

      const {imageUrl} = this.props; // Access props here

        const p5 = require('p5');

        new p5((p) => {
            let img;
            let colors = [
                [255, 255, 255, 50], // White
                [0, 0, 0, 50],       // Black
                [255, 0, 0, 50],     // Red
                [0, 255, 0, 50],     // Green
                [0, 0, 255, 50],     // Blue
                [255, 255, 0, 50],   // Yellow
                [255, 0, 255, 50],   // Magenta
                [0, 255, 255, 50],   // Cyan
                // Add more colors as needed
            ];
            
            let drawTime = 100; // Time in milliseconds
            let timer;
            let numChunks = 10; // Number of chunks(stay between 5 and 25)
            let chunkSize;
            let canv
            p.preload = () => {
                img = p.loadImage(imageUrl);
            };

            p.setup = () => {
                 canv =p.createCanvas(600, 600);
                img.resize(p.width, p.height);
                const randomColor = p.random(colors);
                p.background(255,255,255);
                p.background(randomColor);
                timer = p.millis() + drawTime; // Start the timer
                chunkSize = p.width / numChunks; // Calculate chunk size
            

                while (p.millis() < timer) {
                    // Draw random shapes using colors from the image
                    for (let i = 0; i < 200; i++) {
                        const x = p.int(p.random(p.width));
                        const y = p.int(p.random(p.height));
                        const col = img.get(x, y);
                        p.noStroke();
                        p.fill(p.red(col), p.green(col), p.blue(col), 190);
                        p.push();
                        const length = p.map(p.saturation(col), 0, 255, 0, 120);
                        const angle = p.map(p.hue(col), 0, 255, 0, 180);
                        p.translate(x, y);
                        p.rotate(p.radians(angle));
                        p.rect(0, 0, 1, length);
                        p.pop();
                    }
                } 
                    createMesmerizingArt(p);
                    this.setState({ canvasDataURL: canv.canvas.toDataURL(), isLoading: false });
                    p.remove();
                
            };

            const createMesmerizingArt = (p) => {
                for (let x = 0; x < p.width; x += chunkSize) {
                    for (let y = 0; y < p.height; y += chunkSize) {
                        const chunkImg = p.get(x, y, chunkSize, chunkSize); // Get a chunk of the canvas
                        for (let i = 0; i < chunkSize; i++) {
                            for (let j = 0; j < chunkSize; j++) {
                                const noiseVal = p.noise(x + i * 0.01, y + j * 0.01) * 255;
                                const pixelColor = chunkImg.get(i, j);
                                const r = p.red(pixelColor) + noiseVal;
                                const g = p.green(pixelColor) + noiseVal;
                                const b = p.blue(pixelColor) + noiseVal;
                                chunkImg.set(i, j, p.color(r, g, b));
                            }
                        }
                        p.image(chunkImg, p.random(p.width), p.random(p.height));
                    }
                }
            };

        });
    }

    render() {
        const { canvasDataURL, isLoading } = this.state;

        return isLoading ? (
            <p>Generat...</p>
        ) : (
            <img width={300} height={300} src={canvasDataURL} alt="Canvas Data URL" id='sketchImg' />
        );
    }

}

export default P5Sketch;


